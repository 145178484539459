import { Dialog } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { ClientSafeProvider } from 'next-auth/react'
import React, { FC, useRef, useState } from 'react'
import tw from 'twin.macro'

import { useProviders } from '@/domain/common/useProviders'

import { PROVIDER_BUTTONS } from './LoginButtons'
import { ModalContainer } from './ModalContainer'

type Props = {
  modalOpen: boolean
  onCancel: () => void
  onComplete: () => void
}

/**
 * Modal that displays when doing something that requires a check
 *
 * Has a cancel and confirm button
 */
export const LoginModal: React.FC<Props> = ({ modalOpen, onCancel, onComplete }) => {
  const providers = useProviders()
  return (
    <ModalContainer modalOpen={modalOpen} setModalOpen={() => onCancel()}>
      <div tw="inline-block align-bottom p-4 pt-5 w-full sm:p-6">
        <div tw="sm:flex sm:items-start">
          <div tw="text-center">
            <Dialog.Title
              as="h3"
              tw="text-2xl font-bold text-center text-gray-900 flex gap-2 flex-wrap justify-center items-center"
            >
              Sign In to{' '}
              <span tw="px-2 py-1 bg-black text-white rounded ml-1 whitespace-nowrap">
                social | fitness
              </span>
            </Dialog.Title>
            <p tw="max-w-xs my-6 text-justify text-gray-400">
              To make Social Fitness as simple and secure as possible, we don&apos;t keep any
              passwords in our systems. That means you need to sign up using a third party.
            </p>
            <div tw="mt-2 flex flex-col gap-4 items-center">
              {Object.entries(providers ?? {})
                .filter(([p]) => p in PROVIDER_BUTTONS)
                .map(
                  ([p, v]) =>
                    [PROVIDER_BUTTONS[p as keyof typeof PROVIDER_BUTTONS], v] as [
                      FC<{ callBack: () => void }>,
                      ClientSafeProvider,
                    ],
                )
                .map(([Button, props], index) => (
                  <Button key={index} callBack={() => onComplete()} {...props} tw="w-full" />
                ))}
            </div>
          </div>
        </div>
        {/* <div tw="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            tw="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            css={
              typeText && typeTextValue !== typeText
                ? tw`pointer-events-none cursor-not-allowed bg-gray-300`
                : null
            }
            onClick={onConfirm}
          >
            {confirmText ?? 'Confirm'}
          </button>
          <button
            type="button"
            tw="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onCancel}
            ref={cancelButtonRef}
          >
            {cancelText ?? 'Cancel'}
          </button>
        </div> */}
      </div>
    </ModalContainer>
  )
}
