import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import React, { FC } from 'react'
import tw from 'twin.macro'

import { User } from '@/domain/user/useCurrentUser'
import { Onboarding } from '@/frontend/user/components/Onboarding'

import { Profile } from '../../user/components/Profile'
import { useDebounce } from '../useDebounce'

type NavItem = {
  name: string
  number?: number
  href: string
  active?: boolean
  primary?: boolean
}

type HeaderProps = {
  navigation: NavItem[]
  user?: User
}

const NavigationItem: FC<{ item: NavItem }> = ({ item }) => {
  const lastNumber = useDebounce(item.number, 1000)
  return (
    <Link key={item.name} href={item.href} passHref={true}>
      <a
        tw="flex flex-row self-start items-center px-3 py-2 rounded-md text-base font-medium cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-gray-900 focus:ring-gray-700"
        css={[
          item.active
            ? tw`bg-gray-900 text-white`
            : tw`text-gray-300 hover:bg-gray-700 hover:text-white`,
          // Straight from button primary
          item.primary &&
            tw`text-white bg-gradient-to-r from-primary-500 to-primary-600 border border-transparent hocus:(hover:from-primary-600 hover:to-primary-700 ring-primary-400 outline-none ring-2 ring-offset-2)`,
        ]}
        aria-current={item.active ? 'page' : undefined}
      >
        {item.name}
        {item.number ? (
          <span tw="inline-flex items-center">
            <span
              tw="inline-flex items-center absolute justify-center w-6 h-6 ml-2 rounded-full font-semibold"
              css={lastNumber !== item.number ? tw`animate-ping` : null}
            />
            <span tw="inline-flex items-center z-10 justify-center w-6 h-6 ml-2 rounded-full font-semibold">
              {item.number > 99 ? 99 : item.number}
            </span>
          </span>
        ) : null}
      </a>
    </Link>
  )
}

export const Header: FC<HeaderProps> = ({ navigation, user }) => {
  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div tw="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8">
            <div tw="relative flex items-center justify-between h-16">
              <div tw="inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button tw="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span tw="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon tw="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon tw="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div tw="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div tw="flex-shrink-0 flex items-center text-white font-bold text-xl">
                  <Link href="/" passHref={true}>
                    {/*  Small screens*/}
                    <a tw="flex py-5 rounded-md items-center h-8 w-auto focus:outline-none focus:ring-2 focus:ring-offset-gray-900 focus:ring-gray-700">
                      social | fitness
                    </a>
                  </Link>
                </div>
                <div tw="hidden sm:block sm:ml-6">
                  <div tw="flex space-x-2">
                    {navigation.map((item) => (
                      <NavigationItem key={item.name} item={item} />
                    ))}
                  </div>
                </div>
              </div>
              <div tw="flex items-center pr-2 sm:static sm:inset-auto sm:pr-0">
                {user?.loggedIn ? <Onboarding user={user} /> : null}
                <Profile user={user} />
              </div>
            </div>
          </div>

          <Disclosure.Panel tw="sm:hidden">
            <div tw="px-4 pt-2 pb-3 flex flex-col space-y-1">
              {navigation.map((item) => (
                <NavigationItem key={item.name} item={item} />
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
