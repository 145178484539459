import { useSession } from 'next-auth/react'
import { useMemo } from 'react'

import { User_Type_Enum_Enum, UserFragment, useUserByOAuthIdQuery } from '../../graphql/types'

// TODO(sean): Move to domain
export type User = {
  id?: string
  oauthId?: string
  name?: string
  username?: string
  email?: string
  profilePhoto?: string
  type?: User_Type_Enum_Enum

  createdAt?: Date
  updatedAt?: Date

  accountCreated: boolean
  identityCreated: boolean
  loggedIn: boolean
}

type Props = {
  noFetch?: boolean
}

/**
 * Hook that will fetch the data for the current signed in
 * user from the backend
 */
export const useCurrentUser = ({ noFetch }: Props = { noFetch: false }) => {
  const { data: session, status } = useSession()
  const {
    data,
    previousData,
    loading: graphqlLoading,
    error,
  } = useUserByOAuthIdQuery({
    variables: { oauth_id: session?.oauth_id as string },
    skip: !session?.oauth_id,
    fetchPolicy: noFetch ? 'cache-only' : 'cache-first',
  })

  // also listen for previous data to prevent rerenders
  const serverUser: UserFragment | undefined = useMemo(
    () => (data ?? previousData)?.user[0],
    [data?.user[0]?.username],
  )

  const user: User | null = session
    ? {
        id: serverUser?.id,
        oauthId: session.oauth_id as string,
        username: serverUser?.username,
        name: serverUser?.identity?.name ?? session?.user?.name ?? undefined,
        email: session?.user?.email ?? serverUser?.identity?.private?.email ?? undefined,
        profilePhoto: serverUser?.identity?.profilePhoto ?? session?.user?.image ?? undefined,
        type: serverUser?.type,

        createdAt: serverUser?.created_at ? new Date(serverUser.created_at) : undefined,

        accountCreated: !!serverUser,
        identityCreated: !!serverUser?.identity,
        loggedIn: !!session,
      }
    : null

  return {
    user,
    loading: status === 'loading' || graphqlLoading,
    error: !data ? error : undefined,
  }
}
