import Image from 'next/image'
import React, { FC } from 'react'
import tw from 'twin.macro'

type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Props = {
  size?: Sizes
  photoUrl?: string | null
  displayName?: string
}

/**
 * Circle profile photo for a user.
 *
 * If no profile photo is provided will display
 * a circle
 *
 * If a username is provided will use the first letter in the circle
 */
export const ProfilePhoto: FC<Props> = ({ size = 'md', photoUrl, displayName }) => {
  return (
    <div
      css={[...styles.circle(size), !photoUrl && tw`bg-gray-100`]}
      tw="shadow-md overflow-hidden text-gray-400"
    >
      {photoUrl ? (
        <Image
          width={sizeOfImage[size]}
          height={sizeOfImage[size]}
          src={photoUrl}
          alt="profile photo"
        />
      ) : displayName ? (
        <p css={styles.text(size)}>{displayName[0]}</p>
      ) : (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          width={sizeOfImage[size]}
          height={sizeOfImage[size]}
        >
          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
        </svg>
      )}
    </div>
  )
}

const sizeOfImage: { [key in Sizes]: number } = {
  xs: 32,
  sm: 48,
  md: 64,
  lg: 80,
  xl: 96,
}

const styles = {
  circle: (size?: Sizes) => [
    tw`rounded-full h-16 w-16 flex justify-center items-center object-fill`,
    size === 'xs' && tw`h-8 w-8`,
    size === 'sm' && tw`h-12 w-12`,
    size === 'md' && tw`h-16 w-16`, // Default
    size === 'lg' && tw`h-20 w-20`,
    size === 'xl' && tw`h-24 w-24`,
  ],
  text: (size?: Sizes) => [
    tw`text-3xl text-center font-bold text-primary-700`,
    size === 'xs' && tw`text-lg`,
    size === 'sm' && tw`text-2xl`,
    size === 'md' && tw`text-3xl`, // Default
    size === 'lg' && tw`text-4xl`,
    size === 'xl' && tw`text-5xl`,
  ],
}
