import { css, cx } from '@emotion/css'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, MutableRefObject } from 'react'
import tw from 'twin.macro'

type Props = {
  modalOpen: boolean
  setModalOpen: (open: boolean) => void
  initialFocus?: MutableRefObject<HTMLElement | null>
}

/**
 * Create a container that subtly transitions in with a center white div that has
 * rounded corner styling
 */
export const ModalContainer: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  children,
  initialFocus,
}) => (
  <Transition.Root show={modalOpen} as={Fragment}>
    <Dialog
      as="div"
      tw="fixed z-10 inset-0 overflow-y-auto"
      onClose={setModalOpen}
      initialFocus={initialFocus}
    >
      <div tw="flex items-center justify-center min-h-screen pt-sm px-sm pb-xl text-center">
        <Transition.Child
          as={Fragment}
          {...{
            enter: cx(css(tw`ease-out duration-300`)),
            enterFrom: cx(css(tw`opacity-0`)),
            enterTo: cx(css(tw`opacity-100`)),
            leave: cx(css(tw`ease-in duration-200`)),
            leaveFrom: cx(css(tw`opacity-100`)),
            leaveTo: cx(css(tw`opacity-0`)),
          }}
        >
          <Dialog.Overlay tw="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/*This element is to trick the browser into centering the modal contents. (NOTE: This was added by tailwind UI but I don't think it's needed)*/}
        <span tw="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          {...{
            enter: cx(css(tw`ease-out duration-300`)),
            enterFrom: cx(css(tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`)),
            enterTo: cx(css(tw`opacity-100 translate-y-0 sm:scale-100`)),
            leave: cx(css(tw`ease-in duration-200`)),
            leaveFrom: cx(css(tw`opacity-100 translate-y-0 sm:scale-100`)),
            leaveTo: cx(css(tw`opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95`)),
          }}
        >
          <div tw="flex justify-center items-center align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:(my-8 max-w-xl)">
            {children}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
)
