import Link from 'next/link'
import React from 'react'
import tw from 'twin.macro'

const navigation = {
  main: [
    { name: 'Updates', href: '/updates' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
  ],
  social: [] as { name: string; href: string; icon: React.FC }[],
}

export const Footer = () => {
  return (
    <footer tw="bg-gray-900 text-white">
      <div tw="max-w-5xl mx-auto py-sm px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav tw="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <Link key={item.name} passHref={true} href={item.href}>
              <a tw="mx-5 my-2 text-base hover:text-gray-400">{item.name}</a>
            </Link>
          ))}
        </nav>
        <div tw="mt-xs flex justify-center space-x-6">
          {navigation.social.map((item) => (
            <a key={item.name} href={item.href} tw="text-gray-400 hover:text-gray-500">
              <span tw="sr-only">{item.name}</span>
              <item.icon tw="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <p tw="mt-sm text-center text-base text-gray-400">
          &copy; 2021 Social Fitness All rights reserved.
        </p>
      </div>
    </footer>
  )
}
