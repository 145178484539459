import { useApolloClient, useQuery } from '@apollo/client'
import { css, cx } from '@emotion/css'
import { Menu, Popover, Transition } from '@headlessui/react'
import {
  BellIcon,
  CheckIcon,
  ChevronRightIcon,
  EmojiHappyIcon,
  FingerPrintIcon,
  FireIcon,
  HeartIcon,
  PencilIcon,
  SearchIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import React, { FC, Fragment, useContext, useEffect } from 'react'
import tw from 'twin.macro'

import { ACTIVE_CHALLENGES } from '@/domain/challenge/LocalQueries'
import { OnboardingContext } from '@/domain/common/OnboardingContext'
import { useDebounce } from '@/frontend/common/useDebounce'

import { User } from '../../../domain/user/useCurrentUser'

export type Props = {
  user?: User
}

export const Onboarding: FC<Props> = ({ user }) => {
  const router = useRouter()
  const [state, dispatch] = useContext(OnboardingContext)
  const { data } = useQuery(ACTIVE_CHALLENGES)

  // if there is a created challenge, log it as a completed step
  const createdChallengesActive = data?.createdChallengesActive ?? 0
  useEffect(() => {
    if (createdChallengesActive > 0 && !state.challengeCreated) {
      dispatch({ type: 'update', payload: { challengeCreated: true } })
    }
  }, [createdChallengesActive])

  const options = [
    {
      Icon: UserIcon,
      text: 'Choose a username',
      desc: 'You use it to identify yourself in your community.',
      complete: user?.accountCreated,
      action: () => router.push('/profile'),
    },
    {
      Icon: SearchIcon,
      text: 'Find your people',
      desc: 'Track down your gym or your friends.',
      complete: state.searchComplete,
      action: async () => {
        await router.push('/?search=true')
      },
    },
    // {
    //   Icon: HeartIcon,
    //   text: 'Follow a challenge',
    //   desc: "This way you'll get updated when you win, or not.",
    //   complete: activeChallenges > 0,
    // },
    {
      Icon: FireIcon,
      text: 'Enter a score',
      desc: 'Enter challenges to compete with others and more importantly yourself.',
      action: () => router.push('/current'),
      complete: state.addedScore,
    },
    {
      Icon: PencilIcon,
      text: 'Create a challenge',
      desc: 'If you want to get building your own, you can.',
      complete: state.challengeCreated,
      action: () => router.push('/challenge/new'),
    },
    // {
    //   Icon: EmojiHappyIcon,
    //   text: 'Introduce yourself',
    //   desc: "When you're ready, add a profile photo and name.",
    //   complete: user?.identityCreated,
    //   action: () => router.push('/profile'),
    // },
  ]

  const complete = options.filter((o) => o.complete)
  const incomplete = options.filter((o) => !o.complete)

  const completion = Math.ceil((complete.length / options.length) * 100)

  const lastComplete = useDebounce(complete.length, 1000)

  if (complete.length === options.length) return null

  return (
    <Popover tw="ml-3 relative">
      <Popover.Button tw="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <span tw="sr-only">View notifications</span>
        <span tw="flex">
          <span
            tw="absolute w-6 h-6 bg-green-500 rounded-full"
            css={lastComplete !== complete.length ? tw`animate-ping` : tw`hidden`}
          />
          <BellIcon tw="z-10 w-6 h-6 rounded-full font-bold" aria-hidden="true" />
        </span>
      </Popover.Button>
      <Transition
        as={Fragment}
        {...{
          enter: cx(css(tw`transition ease-out duration-100`)),
          enterFrom: cx(css(tw`transform opacity-0 scale-95`)),
          enterTo: cx(css(tw`transform opacity-100 scale-100`)),
          leave: cx(css(tw`transition ease-in duration-75`)),
          leaveFrom: cx(css(tw`transform opacity-100 scale-100`)),
          leaveTo: cx(css(tw`transform opacity-0 scale-95`)),
        }}
      >
        <Popover.Panel tw="origin-top-right absolute -right-11 mt-2 w-80 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div tw=" ring-1 ring-black ring-opacity-5 overflow-hidden ">
            <div tw="border-b px-4 py-3">Getting Started</div>

            <div tw="px-4 py-3">
              <div tw="relative pt-1">
                <div tw="overflow-hidden h-1 mb-1 text-xs flex rounded-lg bg-green-200">
                  <div
                    style={{ width: `${completion}%` }}
                    tw="shadow-none flex flex-col text-center whitespace-nowrap transition-all text-white justify-center bg-green-500"
                  />
                </div>
              </div>
              <div tw="text-gray-400 text-sm">
                {completion}% complete{completion > 45 ? '— well done!' : null}
              </div>
              <h3 tw="text-xl font-bold mt-4">
                Welcome{user?.username ? ` ${user.username}` : null}!
              </h3>
              <div>
                Lets get you ready to kick some ass with friends. There's a few simple steps.
              </div>
              <ul tw="mt-2">
                {incomplete.map((o, index) => (
                  <li
                    key={index}
                    tw="flex flex-row gap-2 items-center p-2 rounded"
                    css={
                      o.action !== undefined ? tw`cursor-pointer transform hover:bg-gray-100` : null
                    }
                    onClick={o.action}
                  >
                    <o.Icon tw="h-8 w-8 text-green-500" />
                    <div tw="flex-1 ">
                      <div tw="flex flex-row items-center gap-1">{o.text}</div>
                      <div tw="text-sm text-gray-400">{o.desc}</div>
                    </div>
                    {/* {o.action ? <ChevronRightIcon tw="text-gray-500 h-5 w-5" /> : null} */}
                  </li>
                ))}
                {complete.map((o, index) => (
                  <li key={index} tw="flex flex-row gap-2 items-center p-2 rounded">
                    <CheckIcon tw="text-gray-400 h-8 w-8" />
                    <div tw="flex-1 ">
                      <div tw="line-through text-gray-400">{o.text}</div>
                      <div tw="text-sm text-gray-400 line-through">{o.desc}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
