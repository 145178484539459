import { css, cx } from '@emotion/css'
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { signIn, signOut } from 'next-auth/react'
import React, { FC, Fragment } from 'react'
import tw from 'twin.macro'

import { User } from '../../../domain/user/useCurrentUser'
import { ProfilePhoto } from './ProfilePhoto'

export type ProfileProps = {
  user?: User
}

const MenuEntry: FC<any> = ({ item, active, ...rest }) => {
  const inner = (
    <a
      {...rest}
      tw="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
      css={[active ? tw`bg-gray-100` : null, item.css]}
      onClick={item.action?.onClick}
    >
      {item.text}
    </a>
  )

  return item.action?.type === 'link' ? (
    <Link href={item.action?.href} passHref={true}>
      {inner}
    </Link>
  ) : (
    inner
  )
}

export const Profile: FC<ProfileProps> = ({ user }) => {
  const loggedInItems = [
    {
      text: user?.username ? `@${user.username}` : user?.email,
      action: { type: 'link', href: user?.id ? `/user/${user.id}` : '/' }, // TODO(sean): When have a signup flow link from here
      css: tw`font-bold`,
    },
    { text: 'Sign Out', action: { type: 'click', onClick: () => signOut() } },
  ]
  const loggedOutItems = [
    {
      text: 'Sign Up',
      action: { type: 'click', onClick: () => signIn() },
      css: tw`text-primary-500 font-bold`,
    },
    { text: 'Sign In', action: { type: 'click', onClick: () => signIn() } },
  ]

  const menuItems = user?.loggedIn ? loggedInItems : loggedOutItems

  return (
    <Menu as="div" tw="ml-3 relative">
      <Menu.Button tw="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
        <span tw="sr-only">Open user menu</span>
        <ProfilePhoto photoUrl={user?.profilePhoto} size={'xs'} displayName={user?.username} />
      </Menu.Button>
      <Transition
        as={Fragment}
        {...{
          enter: cx(css(tw`transition ease-out duration-100`)),
          enterFrom: cx(css(tw`transform opacity-0 scale-95`)),
          enterTo: cx(css(tw`transform opacity-100 scale-100`)),
          leave: cx(css(tw`transition ease-in duration-75`)),
          leaveFrom: cx(css(tw`transform opacity-100 scale-100`)),
          leaveTo: cx(css(tw`transform opacity-0 scale-95`)),
        }}
      >
        <Menu.Items tw="origin-top-right absolute right-0 mt-2 min-width[12rem] whitespace-nowrap rounded-md shadow-lg py-1 px-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {menuItems.map((item) => (
            <Menu.Item key={item.text}>
              {({ active }) => <MenuEntry item={item} active={active} />}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
