import { signIn } from 'next-auth/react'
import { FC } from 'react'
import tw from 'twin.macro'

const LoginButton = tw.button`inline-flex items-center shadow-md gap-2 px-6 py-3 font-bold border border-transparent text-base rounded-full  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`

const GoogleButton: FC = (props) => (
  <LoginButton type="button" tw="bg-white hover:bg-gray-50" {...props}>
    <svg tw="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path
          fill="#4285F4"
          d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
        />
        <path
          fill="#34A853"
          d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
        />
        <path
          fill="#FBBC05"
          d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
        />
        <path
          fill="#EA4335"
          d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
        />
      </g>
    </svg>
    Sign in with Google
  </LoginButton>
)

const AppleButton: FC = (props) => (
  <LoginButton type="button" tw="bg-black text-white" {...props}>
    <svg tw="w-5 h-5" viewBox="0 0 256 315" preserveAspectRatio="xMidYMid">
      <path
        d="M213.803394,167.030943 C214.2452,214.609646 255.542482,230.442639 256,230.644727 C255.650812,231.761357 249.401383,253.208293 234.24263,275.361446 C221.138555,294.513969 207.538253,313.596333 186.113759,313.991545 C165.062051,314.379442 158.292752,301.507828 134.22469,301.507828 C110.163898,301.507828 102.642899,313.596301 82.7151126,314.379442 C62.0350407,315.16201 46.2873831,293.668525 33.0744079,274.586162 C6.07529317,235.552544 -14.5576169,164.286328 13.147166,116.18047 C26.9103111,92.2909053 51.5060917,77.1630356 78.2026125,76.7751096 C98.5099145,76.3877456 117.677594,90.4371851 130.091705,90.4371851 C142.497945,90.4371851 165.790755,73.5415029 190.277627,76.0228474 C200.528668,76.4495055 229.303509,80.1636878 247.780625,107.209389 C246.291825,108.132333 213.44635,127.253405 213.803394,167.030988 M174.239142,50.1987033 C185.218331,36.9088319 192.607958,18.4081019 190.591988,0 C174.766312,0.636050225 155.629514,10.5457909 144.278109,23.8283506 C134.10507,35.5906758 125.195775,54.4170275 127.599657,72.4607932 C145.239231,73.8255433 163.259413,63.4970262 174.239142,50.1987249"
        fill="white"
      />
    </svg>
    Sign in with Apple
  </LoginButton>
)
const FacebookButton: FC = (props) => (
  <LoginButton type="button" tw="text-white bg-indigo-600 hover:bg-indigo-700" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" tw="w-5 h-5" viewBox="0 0 1365.35 1357.07">
      <path
        d="M1365.333 682.667C1365.333 305.64 1059.693 0 682.667 0 305.64 0 0 305.64 0 682.667c0 340.738 249.641 623.16 576 674.373V880H402.667V682.667H576v-150.4c0-171.094 101.917-265.6 257.853-265.6 74.69 0 152.814 13.333 152.814 13.333v168h-86.083c-84.804 0-111.25 52.623-111.25 106.61v128.057h189.333L948.4 880H789.333v477.04c326.359-51.213 576-333.635 576-674.373"
        fill="#fff"
      />
    </svg>
    Sign in with Facebook
  </LoginButton>
)

function createButton(Component: FC) {
  const Wrapper: FC = ({ id, callBack = () => {} }: any) => (
    // @ts-expect-error(arlyon): ignore
    <Component onClick={async () => callBack(await signIn(id))} />
  )
  Wrapper.displayName = Component.displayName
  return Wrapper
}

export const PROVIDER_BUTTONS = {
  google: createButton(GoogleButton),
  facebook: createButton(FacebookButton),
  apple: createButton(AppleButton),
}
