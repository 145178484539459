import { useApolloClient } from '@apollo/client'
import { Global } from '@emotion/react'
import Head from 'next/head'
import React, { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import tw, { css } from 'twin.macro'

import { ACTIVE_CHALLENGES } from '@/domain/challenge/LocalQueries'
import { useOnlineStatus } from '@/domain/common/OnlineStatusProvider'
import { useSignIn } from '@/domain/common/useSignIn'
import { useCurrentUser } from '@/domain/user/useCurrentUser'
import { LoginModal } from '@/frontend/common/components/LoginModal'

import { Footer, Header } from '../frontend/common/components'
import { useFollowedChallengesQuery } from '../graphql/types'
import type { NextPage } from '../types/next'

type AuthProps = {
  name?: string
  headerItem?: React.ReactNode
}

const Auth: NextPage<AuthProps> = ({ children, name, headerItem }) => {
  const { user, error, loading } = useCurrentUser()
  const client = useApolloClient()
  const online = useOnlineStatus()
  const { open, cancel, complete } = useSignIn()

  const { data } = useFollowedChallengesQuery({
    variables: {
      username: user?.username!,
    },
    skip: !user?.username,
  })

  // show indicators when going on- and offline, except on first render
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (online) {
      toast.success('Your device is back online.')
    } else {
      toast.error('Your device has gone offline.')
    }
  }, [online])

  const createdChallengesActive = data?.user
    .map((u) => u.challengesCreated_aggregate.aggregate?.count ?? 0)
    .reduce((a, b) => a + b, 0)
  const followedChallengesActive = data?.user
    .map((u) => u.challengeFollows_aggregate.aggregate?.count ?? 0)
    .reduce((a, b) => a + b, 0)

  // cache this so that other parts of the app can use the data
  useEffect(() => {
    if (createdChallengesActive !== undefined && followedChallengesActive !== undefined) {
      client.writeQuery({
        query: ACTIVE_CHALLENGES,
        data: { createdChallengesActive, followedChallengesActive },
      })
    }
  }, [createdChallengesActive, followedChallengesActive])

  const navigation = user
    ? [
        { name: 'New Challenge', href: '/challenge/new', primary: true },
        {
          name: 'My Challenges',
          number: (createdChallengesActive ?? 0) + (followedChallengesActive ?? 0),
          href: '/current',
        },
        { name: 'Past Challenges', href: '/past' },
        { name: 'Upcoming Challenges', href: '/upcoming' },
      ]
    : [
        { name: 'New Challenge', href: '/challenge/new', primary: true },
        { name: 'Pricing', href: '/pricing' },
      ]

  return (
    <>
      <Head>
        <title>Social Fitness</title>
        <meta name="description" content="Fitness. But social." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Global
        styles={css`
          #__next {
            ${tw`bg-gray-100 flex flex-col min-h-screen`}
            min-height: 100vh;
          }
          html {
            height: -webkit-fill-available;
          }
        `}
      />
      <div tw="bg-gray-900 pb-32">
        <Header navigation={navigation} user={user || undefined} />
        {name && (
          <header tw={'pt-10'}>
            <div tw="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 tw="text-3xl font-bold text-white">{name}</h1>
              {headerItem}
            </div>
          </header>
        )}
      </div>
      <main tw="-mt-32 flex-1">
        <div tw="max-w-5xl mx-auto pb-12 px-xs sm:px-sm lg:px-md pt-md">
          <div tw="bg-white rounded-lg shadow px-sm py-md sm:px-lg min-height[24rem]">
            {children}
          </div>
        </div>
      </main>
      <Footer />
      {!user?.loggedIn ? (
        <LoginModal modalOpen={open} onCancel={cancel} onComplete={complete} />
      ) : null}
    </>
  )
}

export default Auth
